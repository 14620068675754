<template>
    <div class="animated fadeIn">
        <!-- <CCard :style="viewPreference =='table'? '' : 'display:none'">
            <CCardHeader><font-awesome-icon icon="window-maximize"/> Forms Data</CCardHeader>
            <CCardBody>
                
            </CCardBody>
        </CCard>    -->

       <template v-if="$store.getters.getFormApplication == 'table'">
            <CRow
                style="padding-top: 2%"
                v-if="$store.getters.getFormApplication == 'table'"
                >
                    <CCol lg="1" md="1" s="1" xs="1"></CCol>
                    <CCol lg="4" md="4" s="4" xs="4"></CCol>
                    <CCol lg="1" md="1" sm="1" xs="1">
                    <CButton
                        class="float-right"
                        color="primary"
                        title="Advance Filter"
                        @click="advanceFilter()"
                    >
                    &nbsp;<font-awesome-icon icon="sliders-h" />&nbsp; 
                        <CBadge color="danger" shape="pill" v-if="numberOfFilter > 0">{{ numberOfFilter }}</CBadge>
                    </CButton>
                    </CCol>
                    <CCol lg="4" md="4" s="4" xs="4">
                    <CInput
                        placeholder="Enter Reference Number"
                        type="text"
                        autocomplete="off"
                        v-tocapitalize
                        v-nospecialcharacter
                        v-model="to_search.reference_number"
                        v-on:keyup.enter="searchData"
                    >
                        <template #append>
                        <CButton color="primary" @click="searchData()">
                            <font-awesome-icon icon="search" />
                        </CButton>
                        </template>
                    </CInput>
                    </CCol>
                    <CCol lg="2" md="2" sm="2" xs="2">
                    <CButton
                        class="float-right"
                        color="dark"
                        title="Advance Filter"
                        @click="clearSearch()"
                    >
                        <font-awesome-icon icon="eraser" /> Remove Filter
                    </CButton>
                    </CCol>
            </CRow> 
            <CTabs variant="pills" :active-tab="my_tab" @update:activeTab="changeTab">
                <br>
                <CTab>
                    <template slot="title">
                        Active Form Application/s 
                        <CBadge color="success">( {{ filtered_data ? filtered_data.length : [...new Set(active_forms.map((item) => item.reference_number))].length }} )</CBadge>
                    </template>
                    <CCard>
                        <CCardBody>
                            <CDataTable
                                :items=" filtered_data ? filtered_data : active_forms"
                                :fields="form_data_list_fields"
                                :items-per-page="12"
                                border
                                @row-clicked="rowClicked"
                                sorter
                                hover
                                pagination
                                :loading="this.table_loading"
                            >
                            <template slot="no-items-view" v-if="this.table_loading">
                                <center>
                                    <CSpinner
                                        style="width:4rem;height:4rem;"
                                        color="info"
                                    />
                                </center>
                            </template>
                            <template #form="{item}">
                                <td>
                                    <span v-c-tooltip="{content:item.form_details.form.name, placement: 'top'}">
                                        {{ item.form_details.form.name.length > 25 ? item.form_details.form.name.substring(0, 25) + '...' : item.form_details.form.name }}
                                    </span>
                                </td>
                            </template>

                            <template #started_at="{item}">
                                <td>
                                    <small>{{ `${item.routing ? item.routing.status!=1 ? changeDateFormat(item.routing.started_at) :'N/a' : 'N/a'}` }}</small>
                                </td>
                            </template>

                            <template #updated_at="{item}">
                                <td>
                                    <small>{{ `${item.routing ? item.routing.status==3 ? changeDateFormat(item.routing.updated_at) : 'N/a' : 'N/a'}` }}</small>
                                </td>
                            </template>
                            <template #created_at="{item}">
                                <td>
                                    <small>{{changeDateFormat(item.created_at)}}</small>
                                </td>
                            </template>
                            
                            <template #routing="{item}">
                                <td v-if="item.routing">
                                <CBadge :color="getColorStatus(getDocumentStatus(item.routing.status))">
                                        {{ getDocumentStatus(item.routing.status).charAt(0).toUpperCase() + getDocumentStatus(item.routing.status).slice(1)}}
                                    </CBadge>
                                </td>
                                <td v-else>
                                <i>Not yet routed.</i>
                                </td>
                            </template>
                            
                            <template #action="{item}">
                                <td>
                                    <CButton 
                                        color="warning" 
                                        shape="pill" 
                                        size="sm"
                                        :disabled="copying_form_data ? (item.id == copying_row_id ? true : false) : false"
                                        @click="copyingForm(item)"
                                        v-c-tooltip="{content:'Copy Form Data', placement: 'top'}"

                                    >
                                        <font-awesome-icon :icon="copying_form_data ? (item.id == copying_row_id ? 'circle-notch' : 'copy') : 'copy'" 
                                                                :pulse="copying_form_data ? (item.id == copying_row_id ? true : false) : false"/>
                                    </CButton>
                                    &nbsp;
                                    <template v-if="!item.routing || (item.routing && item.routing.status == 1)">
                                        <router-link :to="{path: `/${$store.getters['getOrganizationName']}/general-access/form-data/${item.reference_number}~${item.form_details.form.id}`} ">
                                            <CButton 
                                                color="info" 
                                                shape="pill" 
                                                size="sm"
                                                v-c-tooltip="{content:'Manage Form Data', placement: 'top'}"
                                            >
                                                <font-awesome-icon icon="cog"/>
                                            </CButton>
                                        </router-link>&nbsp;
                                    </template> 
                                    <template v-if="!item.routing">
                                        <CButton 
                                        shape="pill" 
                                        color="danger"  
                                        :disabled="archiving ? (item.reference_number == archiving_ref_num ? true : false) : false"
                                        size="sm" 
                                        v-c-tooltip="{content:'Archive Form Data', placement: 'top'}"
                                        @click="archive(item)" > 
                                        
                                        <font-awesome-icon :icon="archiving ? (item.reference_number == archiving_ref_num ? 'circle-notch' : 'minus-square') : 'minus-square'" 
                                                                :pulse="archiving ? (item.reference_number == archiving_ref_num ? true : false) : false"/>
                                        </CButton>
                                        &nbsp;
                                    </template>
                                    <CButton 
                                        color="dark" 
                                        shape="pill" 
                                        size="sm"
                                        :disabled="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                        @click="previewFormData(item)"
                                        v-c-tooltip="{content:'Preview Form Data', placement: 'top'}"

                                    >
                                        <font-awesome-icon :icon="previewing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'" 
                                                                :pulse="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"/>
                                    </CButton>
                                    <template v-if="!item.routing">
                                        &nbsp;
                                        <CButton 
                                            color="success" 
                                            shape="pill" 
                                            size="sm"
                                            :disabled="routing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                            @click="routeFormData(item)"
                                            v-c-tooltip="{content:'Route Form Data', placement: 'top'}"

                
                                        >
                                            <font-awesome-icon :icon="routing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'paper-plane') : 'paper-plane'" 
                                                                    :pulse="routing_form_data ? (item.id == selected_row_id ? true : false) : false"/>
                                        </CButton>
                                    </template>
                                </td>
                            </template>

                            <!-- <template #status="{item}">
                                <td>
                                    <CBadge :color="getColorStatus(!item.deleted_at ? 'Active' : 'Inactive')">
                                        {{!item.deleted_at ? 'Active' : 'Inactive'}}
                                    </CBadge>
                                </td>
                            </template> -->
                            </CDataTable>
                        </CCardBody>
                    </CCard>
                </CTab>
                <CTab>
                    <template slot="title">
                        Inactive Form Application/s <CBadge color="danger">( {{ filtered_data_inactive ? filtered_data_inactive.length : [...new Set(inactive_forms.map((item) => item.reference_number))].length }} )</CBadge>
                    </template>
                    <CCard>
                        <CCardBody>
                            <CDataTable
                                :items="filtered_data_inactive ? filtered_data_inactive :inactive_forms"
                                :fields="form_data_list_fields"
                                :items-per-page="12"
                                border
                                hover
                                sorter
                                pagination
                                :loading="this.table_loading"
                            >
                            <template slot="no-items-view" v-if="this.table_loading">
                                <center>
                                    <CSpinner
                                        style="width:4rem;height:4rem;"
                                        color="info"
                                    />
                                </center>
                            </template>

                            <template #form="{item}">
                                <td>
                                    <span v-c-tooltip="{content:item.form_details.form.name, placement: 'top'}">
                                        {{ item.form_details.form.name.length > 25 ? item.form_details.form.name.substring(0, 25) + '...' : item.form_details.form.name }}
                                    </span>
                                </td>
                            </template>

                            <template #updated_at="{item}">
                                <td>
                                    <small>{{changeDateFormat(item.updated_at)}}</small>
                                </td>
                            </template>

                            <!-- <template #routing="{item}">
                                <td v-if="item.routing">
                                <CBadge :color="getColorStatus(getDocumentStatus(item.routing.status))">
                                        {{ getDocumentStatus(item.routing.status).charAt(0).toUpperCase() + getDocumentStatus(item.routing.status).slice(1)}}
                                    </CBadge>
                                </td>
                                <td v-else>
                                <i>Not yet routed.</i>
                                </td>
                            </template> -->
                            <template #action="{item}">
                                <td>
                                    <template v-if="$store.getters['can']('restore-form-data')">
                                        <CButton 
                                            size="sm"
                                            color="success" 
                                            shape="pill"
                                            :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                            @click="restore(item)"
                                            v-c-tooltip="{content:'Restore', placement: 'top'}"
                                        >

                                            <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'" 
                                                        :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/>
                                        </CButton>
                                    </template>
                                    <template v-else>
                                        <i><b>No permission to restore.</b></i>
                                    </template>
                                </td>

                                <!-- <td> -->
                                    <!-- <template v-if="!item.routing">
                                        <router-link :to="{path: `/${$store.getters['getOrganizationName']}/general-access/form-data/${item.reference_number}~${item.form_details.form.id}`}">
                                            <CButton 
                                                color="info" 
                                                shape="pill" 
                                                size="sm"
                                            >
                                                <font-awesome-icon icon="cog"/> Manage
                                            </CButton>
                                        </router-link>
                                    </template>
                                    <CButton 
                                        color="primary" 
                                        shape="pill" 
                                        size="sm"
                                        :disabled="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                        @click="previewFormData(item)"

                                    >
                                        <font-awesome-icon :icon="previewing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'" 
                                                                :pulse="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"/> Preview Form Data
                                    </CButton>
                                    <template v-if="!item.routing && !item.deleted_at">
                                        <CButton 
                                            color="warning" 
                                            shape="pill" 
                                            size="sm"
                                            :disabled="routing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                            @click="routeFormData(item)"
                
                                        >
                                            <font-awesome-icon :icon="routing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'paper-plane') : 'paper-plane'" 
                                                                    :pulse="routing_form_data ? (item.id == selected_row_id ? true : false) : false"/> Route Form Data
                                        </CButton>
                                    </template> -->
                                <!-- </td> -->
                            </template>
                            <template #preview="{item}">
                                <td>
                                    <center>
                                        <CButton 
                                            color="warning" 
                                            shape="pill" 
                                            size="sm"
                                            :disabled="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"
                                            @click="previewFormData(item)"
                                            v-c-tooltip="{content:'Preview Form Data', placement: 'top'}"
                                        >
                                            <font-awesome-icon :icon="previewing_form_data ? (item.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'" 
                                                                    :pulse="previewing_form_data ? (item.id == selected_row_id ? true : false) : false"/>
                                        </CButton>
                                    </center>
                                </td>
                            </template>
                            <!-- <template #status="{item}">
                                <td>
                                    <CBadge :color="getColorStatus(!item.deleted_at ? 'Active' : 'Inactive')">
                                        {{!item.deleted_at ? 'Active' : 'Inactive'}}
                                    </CBadge>
                                </td>
                            </template> -->
                            </CDataTable>
                        </CCardBody>
                    </CCard>
                </CTab>
            </CTabs>

       </template>
        <!-- FORM APPLICATION CARD VIEW -->
        <CRow style="padding-top: 2%" v-if="$store.getters.getFormApplication=='card'">
            <CCol lg="1" md="1" s="1" xs="1"></CCol>
            <CCol lg="4" md="4" s="4" xs="4"></CCol>
            <CCol lg="1" md="1" sm="1" xs="1">
            <CButton
                class="float-right"
                color="primary"
                title="Advance Filter"
                @click="advanceFilter()"
            >
            &nbsp;<font-awesome-icon icon="sliders-h" />&nbsp;
                 <CBadge color="danger" shape="pill" v-if="numberOfFilter > 0">{{ numberOfFilter }}</CBadge>
            </CButton>
            </CCol>
            <CCol lg="4" md="4" s="4" xs="4">
            <CInput
                placeholder="Enter Reference Number"
                type="text"
                autocomplete="off"
                v-tocapitalize
                v-nospecialcharacter
                v-model="to_search.reference_number"
                v-on:keyup.enter="searchData"
            >
                <template #append>
                <CButton color="primary" @click="searchData()">
                    <font-awesome-icon icon="search" />
                </CButton>
                </template>
            </CInput>
            </CCol>
            <CCol lg="2" md="2" sm="2" xs="2">
            <CButton
                class="float-right"
                color="dark"
                title="Advance Filter"
                @click="clearSearch()"
            >
                <font-awesome-icon icon="eraser" /> Remove Filter
            </CButton>
            </CCol>
        </CRow>
        <CRow :style="viewPreference=='card' ? '' :'display:none'">
          <CCol lg="12">
            <CTabs variant="pills" :active-tab="my_tab" @update:activeTab="changeTab">
                <CTab>
                    <template slot="title">
                        Active Form Application/s <CBadge color="success">( {{  filtered_data ? filtered_data.length : [...new Set(active_forms.map((item) => item.reference_number))].length }} )</CBadge>
                    </template>

                    <CRow>
                        <CCol xl="3" lg="3" md="6" sm="12" v-for="(form) in paginatedActiveForms" v-bind:key="form.id">
                            <CWidgetProgress :class="['custom', getColorStatus(getDocumentStatus(form.routing ? form.routing.status : 'no-data'))]">
                                <template #default>
                                    <CRow>
                                        <CCol lg="12" class="card-container">
                                            
                                            <template v-if="parseInt(form.routing?.status) != 2 && parseInt(form.routing?.status) != 3">
                                                <router-link  :to="{path: `/${$store.getters['getOrganizationName']}/general-access/form-data/${form.reference_number}~${form.form_details.form.id}`}" style="text-decoration: none">
                                                    <h4 v-c-tooltip="{content:form.reference_number, placement: 'top'}">
                                                            <!-- <font-awesome-icon 
                                                            :style="
                                                            {'color': 'grey'}"
                                                            v-c-tooltip="{content:form.form_details.form.name, placement: 'top'}"
                                                            icon="tag" size="lg"/> -->
                                                    
                                                            <span >
                                                                {{form.reference_number.length > 17 ? form.reference_number.substring(0, 17) + '...' :  form.reference_number}}
                                                            </span>
                                                    </h4>
                                                </router-link>
                                            </template>
                                            
                                            <template v-else>
                                                <h4  style="color: #0074D9 !important;">
                                                    <!-- <font-awesome-icon 
                                                    :style="
                                                    {'color': 'grey'}"
                                                    v-c-tooltip="{content:form.form_details.form.name, placement: 'top'}"
                                                    icon="tag" size="lg"/> -->
                                            
                                                        <span >
                                                            {{form.reference_number.length > 17 ? form.reference_number.substring(0, 17) + '...' :  form.reference_number}}
                                                        </span>
                                                </h4>
                                            </template>

                                            

                                            <a href="javascript:void(0);" style="text-decoration: none; cursor: default;">
                                                <small class="text-muted" v-if="form.routing">
                                            
                                                    Routing Status:
                                                    <CBadge :color="getColorStatus(getDocumentStatus(form.routing.status))">
                                                        {{ getDocumentStatus(form.routing.status).charAt(0).toUpperCase() + getDocumentStatus(form.routing.status).slice(1)}}
                                                    </CBadge>
                                                </small>
                                                <small class="text-muted" v-else>
                                                    Routing Status : <i>Not yet routed.</i>
                                                </small>
                                                
                                                <small class="text-muted"><br>
                                                     {{ `Date Routed: ${form.routing ? form.routing.status!=1 ? changeDateFormat(form.routing.started_at) : 'N/a' : 'N/a'}` }}
                                                </small>
                                                
                                                <small class="text-muted"><br>
                                                    {{ `Date Completed: ${form.routing ? form.routing.status==3 ? changeDateFormat(form.routing.updated_at) : 'N/a' : 'N/a'}` }}
                                                </small>
                                                <br>
                                                <br>
                                                <label v-c-tooltip="{content:form.form_details.form.name, placement: 'top'}">
                                                    {{ form.form_details.form.name.length > 25 ? form.form_details.form.name.substring(0, 25) + '...' : form.form_details.form.name}}
                                                </label>
                                                <br>
                                                <small class="text-muted">
                                                    {{`Created At: ${changeDateFormat(form.updated_at)}`}}
                                            </small>
                                            </a>
                                            
                                        </CCol>
                                    </CRow>
                                    <br >
                                    <CRow>
                                        <CCol>
                                            <CButton 
                                                color="warning" 
                                                shape="pill" 
                                                size="sm"
                                                :disabled="copying_form_data ? (form.id == copying_row_id ? true : false) : false"
                                                @click="copyingForm(form)"
                                                v-c-tooltip="{content:'Copy Form Data', placement: 'top'}"

                                            >
                                                <font-awesome-icon :icon="copying_form_data ? (form.id == copying_row_id ? 'circle-notch' : 'copy') : 'copy'" 
                                                                        :pulse="copying_form_data ? (form.id == copying_row_id ? true : false) : false"/>
                                            </CButton>
                                        </CCol>
                                        <CCol v-if="!form.routing || (form.routing && form.routing.status == 1)">
                                            <router-link :to="{path: `/${$store.getters['getOrganizationName']}/general-access/form-data/${form.reference_number}~${form.form_details.form.id}`} ">
                                                <CButton 
                                                    color="info" 
                                                    shape="pill" 
                                                    size="sm"
                                                    v-c-tooltip="{content:'Manage Form Data', placement: 'top'}"
                                                    >
                                                    <font-awesome-icon icon="cog"/>
                                                </CButton>
                                            </router-link>                                            
                                        </CCol>
                                        <CCol v-if="!form.routing">
                                            <CButton 
                                            shape="pill" 
                                            color="danger"  
                                            :disabled="archiving ? (form.reference_number == archiving_ref_num ? true : false) : false"
                                            size="sm" 
                                            v-c-tooltip="{content:'Archive Form Data', placement: 'top'}"
                                            @click="archive(form)" > 
                                            
                                            <font-awesome-icon :icon="archiving ? (form.reference_number == archiving_ref_num ? 'circle-notch' : 'minus-square') : 'minus-square'" 
                                                                :pulse="archiving ? (form.reference_number == archiving_ref_num ? true : false) : false"/>
                                        </CButton>
                                        </CCol>
                                        <CCol>
                                            <CButton 
                                                shape="pill"
                                                color="dark" 
                                                size="sm"
                                                    v-c-tooltip="{content:'Preview Form Data', placement: 'top'}"
                                                :disabled="previewing_form_data ? (form.id == selected_row_id ? true : false) : false"
                                                @click="previewFormData(form)"
                                            > 
                                            <font-awesome-icon :icon="previewing_form_data ? (form.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'" 
                                            :pulse="previewing_form_data ? (form.id == selected_row_id ? true : false) : false"/>
                                            </CButton>
                                        </CCol>
                                        <CCol>
                                            <template v-if="!form.routing">
                                                <CButton
                                                    color="success" 
                                                    shape="pill" 
                                                    size="sm"
                                                    v-c-tooltip="{content:'Route Form Data', placement: 'top'}"
                                                    :disabled="routing_form_data ? (form.id == selected_row_id ? true : false) : false"
                                                    @click="routeFormData(form)"
                        
                                                >
                                                    <font-awesome-icon :icon="routing_form_data ? (form.id == selected_row_id ? 'circle-notch' : 'paper-plane') : 'paper-plane'" 
                                                                            :pulse="routing_form_data ? (form.id == selected_row_id ? true : false) : false"/>
                                                </CButton>
                                            </template>
                                        </CCol>
                                    </CRow>
                                </template>
                            </CWidgetProgress>
                            </CCol>
                    </CRow>
                    <CRow style="padding-top: 3%;">
                        <CCol lg="12" v-if=" data_loaded && active_forms.length <=0 || data_loaded && (filtered_data ? filtered_data.length <=0 : null)">
                            <h4>No data found.</h4>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol lg="12">
                            <CPagination
                            id="active-pagination"
                            :pages="number_of_pages_active"
                            :double-arrows="false"
                            :dots="false"
                            :active-page.sync="current_page_active"
                            @update:activePage="updateActivePage"
                            >
                            <template #previous-button>
                                <font-awesome-icon icon="chevron-left" /> Previous
                            </template>
                            <template #next-button>
                                Next <font-awesome-icon icon="chevron-right" />
                            </template>
                            </CPagination>
                        </CCol>
                    </CRow>
                </CTab>

                <CTab>
                    <template slot="title">
                        Inactive Form Application/s <CBadge color="danger">( {{  filtered_data_inactive ? filtered_data_inactive.length : [...new Set(inactive_forms.map((item) => item.reference_number))].length }} )</CBadge>
                    </template>

                    <CRow>
                        <CCol xl="3" lg="3" md="6" sm="12" v-for="(form) in paginatedInactiveForms" :key="form.id">
                            <CWidgetProgress :class="['custom', 'inactive']">
                            <template #default>
                                <CRow>
                                <CCol lg="12">
                                    <a href="javascript:void(0);" style="text-decoration: none;">
                                    <h4 v-c-tooltip="{content:form.reference_number, placement: 'top'}">
                                        <!-- <font-awesome-icon
                                        :style="{'color': 'grey'}"
                                        v-c-tooltip="{content: form.form_details.form.name, placement: 'top'}"
                                        icon="tag" size="lg"
                                        /> -->
                                        <span v-c-tooltip="{content: form.reference_number, placement: 'top'}">
                                        {{form.reference_number.length > 17 ? form.reference_number.substring(0, 17) + '...' : form.reference_number}}
                                        </span>
                                    </h4>
                                    <small class="text-muted" v-if="form.routing">
                                        Routing Status:  
                                                    <CBadge :color="getColorStatus(getDocumentStatus(form.routing.status))">
                                                        {{ getDocumentStatus(form.routing.status).charAt(0).toUpperCase() + getDocumentStatus(form.routing.status).slice(1)}}
                                                    </CBadge>
                                    </small>
                                    <small class="text-muted" v-else>
                                        Routing Status : <i>Not yet routed.</i>
                                    </small>
                                    <br>
                                    <br>
                                    <label v-c-tooltip="{content:form.form_details.form.name, placement: 'top'}">
                                        {{ form.form_details.form.name.length > 25 ? form.form_details.form.name.substring(0, 25) + '...' : form.form_details.form.name }}
                                    </label>
                                    <br>
                                    <small class="text-muted">
                                        {{`Inactivity Date: ${changeDateFormat(form.updated_at)}`}}
                                    </small>
                                    </a>
                                </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                <CCol>
                                    <template v-if="$store.getters['can']('restore-form-data')">
                                    <CButton
                                        block
                                        size="sm"
                                        color="success"
                                        shape="pill"
                                        :disabled="restoring ? (form.id == restoring_id ? true : false) : false"
                                        v-c-tooltip="{content:'Restore', placement: 'top'}"
                                        @click="restore(form)"
                                    >
                                        <font-awesome-icon :icon="restoring ? (form.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'"
                                        :pulse="restoring ? (form.id == restoring_id ? true : false) : false"
                                        />
                                    </CButton>
                                    </template>
                                    <template v-else>
                                    <i><b>No permission to restore.</b></i>
                                    </template>
                                </CCol>

                                <CCol>
                                    <CButton
                                    block
                                    class="rounded-pill"
                                    color="dark"
                                    size="sm"
                                    :disabled="previewing_form_data ? (form.id == selected_row_id ? true : false) : false"
                                    v-c-tooltip="{content: 'Preview Form Data', placement: 'top'}"
                                    @click="previewFormData(form)"
                                    >
                                    <font-awesome-icon :icon="previewing_form_data ? (form.id == selected_row_id ? 'circle-notch' : 'eye') : 'eye'"
                                        :pulse="previewing_form_data ? (form.id == selected_row_id ? true : false) : false"
                                    />
                                    </CButton>
                                </CCol>
                                </CRow>
                            </template>
                            </CWidgetProgress>
                        </CCol>
                    </CRow>
                    <CRow style="padding-top: 3%">
                        <CCol lg="12" v-if="data_loaded && inactive_forms.length <= 0 || data_loaded && (filtered_data_inactive ? filtered_data_inactive.length<=0 : null)">
                            <h4>No data found.</h4>
                        </CCol>
                    </CRow>

                    <CRow>
                        <CCol lg="12">
                            <CPagination
                            id="pajineyt"
                            :pages="number_of_pages_inactive"
                            :double-arrows="false"
                            :dots="false"
                            :active-page.sync="current_page_inactive"
                            @update:activePage="updateInactivePage"
                            >
                            <template #previous-button>
                                <font-awesome-icon icon="chevron-left" /> Previous
                            </template>
                            <template #next-button>
                                Next <font-awesome-icon icon="chevron-right" />
                            </template>
                            </CPagination>
                        </CCol>
                    </CRow>
                </CTab>
            </CTabs>
          </CCol>
        </CRow>
        <template>
            <CCard style="display:none">
            <!-- <CCard> -->
                <CCardBody>
                    <CRow>
                        <CCol lg="12">
                            <FormDataPreview ref="_formDataRoute" id="form_data"/>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </template>
        <CModal 
            color="primary" 
            :show.sync="preview_form_data" 
            centered
            :closeOnBackdrop="false"
            size="xl"
        >
            <template #header>
                <h5><font-awesome-icon icon="window-maximize" /> Form Data Preview</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="preview_form_data = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper v-if="preview_form_data">
                <CCard>
                    <CCardBody>
                        <CRow>
                            <CCol lg="12">
                                <FormDataPreview ref="_formDataPreview"/>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </template>
            <template #footer>
                <hr>
            </template>
        </CModal>
        <!-- ADVANCE FILTER MODAL -->
        <CModal
            :show.sync="custom_filter"
            color="primary"
            :closeOnBackdrop="false"
        >
            <template #header>
                <h5> <font-awesome-icon icon="sliders-h"/> Advance Filter </h5>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="custom_filter = false"
                >
                    <font-awesome-icon icon="times"/>
                </CButton>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="12">
                            <label>
                                Select Date Created Range:
                            </label>
                            <CDateRange :showClearBtn="true" ref="_CDateRange"/>
                        </CCol>
                    </CRow>
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <label> Select Form </label>
                            <v-select 
                                    placeholder="Select one."
                                    label="name"
                                    :options="form_list"
                                    v-model="to_search.form"
                                />
                        </CCol>
                    </CRow>
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <label> Routing Status </label>
                            <v-select 
                                    placeholder="Select one."
                                    label="viewValue"
                                    :options="routing_status"
                                    v-model="to_search.status"
                                />
                        </CCol>
                    </CRow>
                    <br>
                    <CRow>
                        <CCol lg="12">
                            <CButton
                                :disabled="submit_advance_filter"
                                class="float-right"
                                color="primary"
                                @click="!submit_advance_filter ? searchDataAdvanced() : ''"
                            >
                                <font-awesome-icon 
                                    :icon="!submit_advance_filter ? 'share-square' : 'circle-notch'"
                                    :pulse="submit_advance_filter"
                                /> Search
                            </CButton>
                        </CCol>
                    </CRow>
                </CCardBody>

            </template>
            <template #footer>
                <hr hide>
            </template>

        </CModal>
    </div>
</template>
<script>
import html2pdf from 'html2pdf.js'
import FormManagementJS from '../js/form-management.js';
import FormDataPreview from './components/FormDataPreview';
import CDateRange from '../../components/CDateRange'
export default {
    name: 'FDLists',
    components: {
        FormDataPreview,
        CDateRange
    },
    data(){
        return {
            my_tab: 0,
            printout_source: '',
            selected_row_id: null,
            previewing_form_data:false,
            preview_form_data: false,
            routing_form_data:false,
            form_data_preview_loaded: false,
            table_loading: true,

            copying_form_data: false,
            copying_row_id: null,

            archiving: false,
            archiving_ref_num: null,

            
            restoring: false,
            restoring_id: null,
            form_data_list: [
                // {id: 1, name: 'Document 1305', form: 'CRF', status: 'Active'},
                // {id: 2, name: 'Approval 2316', form: 'PRS', status: 'Active'},
                // {id: 3, name: 'JO-PRS 137', form: 'JO', status: 'Active'},
            ],
            viewPreference: null,
            data_loaded:false,
            to_search : {
                reference_number: null,
                form : null,
                date_from: null,
                date_to: null,
                status : null
            },
            submit_advance_filter: false,
            custom_filter : false,
            form_list : [], //form v-select form advance filter
            filtered_data : null, //for search data active
            filtered_data_inactive : null, // for search data inactive
            paginatedForms: [],
            active_forms_list: [],
            inactive_forms_list: [],
            total_records_active: 0,
            total_records_inactive: 0,
            per_page: 12,
            current_page_active: 1,
            current_page_inactive: 1,
            numberOfFilter : 0,
            routing_status : [
               { viewValue : 'Not yet routed', value : null},
               { viewValue : 'Draft', value : 1},
               { viewValue : 'Signing', value : 2},
               { viewValue : 'Completed', value : 3},
            ]
        }
    },
    created() {
        this.viewPreference = this.$store.getters.getFormApplication;
        this.getFormsData();
        this.$emit('activeTab', 0);
    },
    computed: {
        form_data_list_fields: function () {
            let custom_fields = [];

            if(this.my_tab) {
                custom_fields.push({ key: 'preview', label: ' ', _classes: 'th-icon' })
            }

            
            custom_fields.push(
                { key: 'reference_number', label: 'Reference Number'},
                { key: 'form'},
            )


            if(this.my_tab) {
                custom_fields.push({ key: 'updated_at', label: 'Inactivity Date' })
            } else {
                custom_fields.push({ key: 'created_at', label: 'Date Created' })
                custom_fields.push({ key: 'routing', label: 'Routing Status'})
                custom_fields.push({ key: 'started_at', label: 'Date Routed'})
                custom_fields.push({ key: 'updated_at', label: 'Date Completed'}
                )

            }
            

            if(this.$store.getters.can('view-form-data')) {
                custom_fields.push({ key: 'action', sorter: false, _classes:'td-actn-fxd-wdth'})
            }
            return custom_fields;
        },
        
        active_forms : function() {
            let active_list = this.form_data_list.filter((item) => {
                return item.deleted_at == null;
            });
            return active_list;
        },

        inactive_forms: function () {
            let inactive_list = this.form_data_list.filter((item) => {
                return item.deleted_at != null;
            });
            return inactive_list;
        },

        paginatedActiveForms() {
            const start = (this.current_page_active - 1) * this.per_page;
            const end = start + this.per_page;
            return this.filtered_data ? this.filtered_data.slice(start, end) : this.active_forms_list.slice(start, end);
        },
        paginatedInactiveForms() {
            const start = (this.current_page_inactive - 1) * this.per_page;
            const end = start + this.per_page;
            return this.filtered_data_inactive ? this.filtered_data_inactive.slice(start, end) : this.inactive_forms_list.slice(start, end);
        },
        number_of_pages_inactive() {
            const listLength = this.filtered_data_inactive ? this.filtered_data_inactive.length : this.inactive_forms_list.length;
            return Math.ceil(listLength / this.per_page);
        },
        number_of_pages_active() {
            const listLength = this.filtered_data ? this.filtered_data.length : this.active_forms_list.length;
            return Math.ceil(listLength / this.per_page);
        },
    },
    methods: {
        copyingForm: function (item) {
            this.copying_form_data = true;
            this.copying_row_id = item.id;
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to copy this form data.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    let using_series = item.form_details.form.prefix == "Default" ? 0 : 1;
                    let data = {
                        r_nmbr: this.paramEncoder(item.reference_number),
                        f_id: this.paramEncoder(item.form_details.form_id),
                        srs: using_series,
                    }
                    return axios.post('/form-managements/form-data/copy-form-data', data, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${response.data.data}</b> form application has sucessfully added. Do you want to open the form?`,
                                showCancelButton: true,
                                confirmButtonColor: "#DD6B55",
                                confirmButtonText: "Yes, open form.",
                            }).then((result) => {
                                if(result.value){ 
                                    this.$router.push(`/${this.$store.getters.getOrganizationName}/general-access/form-data/${response.data.data}`);
                                } else {
                                    this.getFormsData()
                                }
                                this.copying_form_data = false;
                                this.copying_row_id = null;
                            })
                            this.$Progress.finish();
                        } else {
                            this.copying_form_data = false;
                            this.copying_row_id = null;
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                    this.copying_form_data = false;
                    this.copying_row_id = null;
                }
            })
        },
        archive: function (item) {
            this.archiving = true;
            this.archiving_ref_num = item.reference_number;
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to archive the <b>${item.reference_number}</b>.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/form-managements/form-data/archive/' + item.reference_number.split('~')[0], {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${item.reference_number} successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })

                            
            // let selected = this.form_data_list.filter((c) => c.reference_number == item.reference_number)[0]
            // if(selected) selected.deleted_at = this.changeDateFormat(this.currentDateTimeWithUsersTimeZone());
            this.getFormsData();
                            this.$Progress.finish()
                        }
                        
            this.archiving = false;
            this.archiving_ref_num = null;
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                    this.archiving = false;
                    this.archiving_ref_num = null;
                }
            })
        },
        restore: function (item) {
            this.restoring = true
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/form-managements/form-data/restore/' + item.reference_number, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${item.form_details.form.name} successfully restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
            this.restoring = false
            this.restoring_id = null;
                    // this.form_data_list.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    this.getFormsData();
                    this.$Progress.finish()
                }
            })
        },
        changeTab: function (item) {
            this.my_tab = item;
        },
        generateLink(form) {
            const organizationName = this.$store.getters['getOrganizationName'];
            return `/${organizationName}/general-access/form-data/${form.reference_number}~${form.form_details.form.id}`;
        },
        rowClicked(item, index, column, e) {
            if(column != 'action') {
                if(item.routing && parseInt(item.routing.status) == 2 || item.routing && parseInt(item.routing.status) == 3) {
                    return;
                }
                const link = this.generateLink(item);
                this.$router.push(link);
            }
        },
        sortFormDataList: function(array) {
            return array.sort(function (x, y) {
                return y.reference_number - x.reference_number;
            });
        },
        getFormsData() {
            this.$Progress.start();
            if (!this.to_search.reference_number) { delete this.to_search.reference_number; }
            if (!this.to_search.form) { delete this.to_search.form; }
            if (!this.to_search.date_from) { delete this.to_search.date_from; }
            if (!this.to_search.date_to) { delete this.to_search.date_to; }
            if(!this.to_search.status) {delete this.to_search.status}
            this.data_loaded = false;
            this.form_data_list = [];
            axios.get('/form-managements/form-data/list', { validateStatus: () => true }).then(response => {
                if (response.status === 200) {
                let data = response.data.data ? response.data.data.sort((x, y) => new Date(y.created_at) - new Date(x.created_at)) : [];
                if (this.to_search.date_to && this.to_search.date_from || this.to_search.form || this.to_search.status) {
                    let date_to = new Date(this.to_search.date_to);
                    let date_from = new Date(this.to_search.date_from);
                    let filteredWithDates = data.filter(item => {
                        let created_date = new Date(item.created_at);
                        return created_date >= date_from && created_date <= date_to;
                    });

                    
                    if (this.to_search.form) {
                        this.form_data_list = filteredWithDates.length > 0 ? 
                        filteredWithDates.filter(x => x.form_details.form_id === this.to_search.form.id) :  data.filter(x => x.form_details.form_id === this.to_search.form.id);
                    } 


                    if(this.to_search.status) {
                        let list =  this.form_data_list.length>0 ?  this.form_data_list : filteredWithDates.length>0 ? filteredWithDates : data;
                        let filtered_list = this.to_search.status.value==null ? list.filter(x=>x.routing==null) : list.filter(x=>x.routing!=null);
                        this.form_data_list = this.to_search.status.value==null ? filtered_list : filtered_list.filter(x => x.routing.status == this.to_search.status.value);
                    }


                    if(this.to_search.form == null && this.to_search.status == null) {
                        this.form_data_list = filteredWithDates;
                    }
                } else {
                    this.form_data_list = data;
                }
                this.table_loading = false;
                this.data_loaded = true;
                this.submit_advance_filter = false;
                this.custom_filter = false;
                this.$Progress.finish();
                }
            });
        },
        previewFormData: function (item) {
            this.printout_source = item.form_details.form.printout_source
            this.$Progress.start();
            switch (this.printout_source) {
                case 'Default':
                    this.preview_form_data = true;
                    this.previewing_form_data = true
                    this.selected_row_id = item.id;
                    this.getFormDataDetails(item).then(data => {
                        let rowCounter = 0;
                        this.form_data_preview_loaded = true;
                        for (let index = 0; index < data.form_detail_fields.length; index++) {
                            const elements = data.form_detail_fields[index].form_detail_field_value;
                            for (let i = 0; i < elements.length; i++) {
                                const el = elements[i];
                                if(parseInt(el.line_number) > rowCounter) {
                                    rowCounter = parseInt(el.line_number)
                                }
                            }
                        }

                        let sorted_detail_fields = FormManagementJS.sortDetailFields(data.form_detail_fields);

                        this.$nextTick(() => {
                            this.$refs._formDataPreview.control_number = `Ref. No.: ${item.reference_number}`
                            this.$refs._formDataPreview.detailsRowCount = rowCounter;
                            this.$refs._formDataPreview.organization_logo = this.$store.getters.getOrganizationLogo;
                            this.$refs._formDataPreview.header_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'header');
                            this.$refs._formDataPreview.detail_fields = FormManagementJS.generateDetailField(sorted_detail_fields);
                            this.$refs._formDataPreview.footer_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'footer');
                            this.$refs._formDataPreview.header_footer_field_values = FormManagementJS.generateHeaderFooterFieldValue(data.form_header_footer_fields);
                            this.$refs._formDataPreview.detail_field_values = FormManagementJS.generateDetailFieldValue(data.form_detail_fields);
                            this.$Progress.finish()
                        })
                    })
                    
                break;   

                default:
                    let data = {
                        reference_number:item.reference_number,
                        printout_source:this.printout_source,
                        action:'preview'
                    }
                    axios.post('/form-managements/form-data/route-custom-form',data,{validateStatus: () => true}).then(response => {
                        if(response.status == 200) {
                            var byteCharacters = atob(response.data);
                            var byteNumbers = new Array(byteCharacters.length);
                            for (var i = 0; i < byteCharacters.length; i++) {
                              byteNumbers[i] = byteCharacters.charCodeAt(i);
                            }
                            var byteArray = new Uint8Array(byteNumbers);
                            var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                            var fileURL = URL.createObjectURL(file);
                            window.open(fileURL); 
                            this.$Progress.finish()
                        }
                    })

                break;
            }


        },
        getFormDataDetails: function (parameter) {
            let params_id = `${parameter.reference_number}~${parameter.form_details.form.id}`;
             return new Promise((resolve, reject) => {
                axios.get('/form-managements/form-data/show/' + params_id, {validateStatus: () => true}).then(response => {
                    if( response.status == 200 ) {
                        let data = response.data.data
                        resolve(data);
                    }
                })
            })
        },
        routeFormData:function (item) {
            this.printout_source = item.form_details.form.printout_source
            this.$Progress.start();
            switch (this.printout_source) {
                case 'Default':
                    this.selected_row_id = item.id;
                    this.routing_form_data = true
                    this.getFormDataDetails(item).then(data => {
                        let rowCounter = 0;
                        this.form_data_preview_loaded = true;
                        for (let index = 0; index < data.form_detail_fields.length; index++) {
                            const element = data.form_detail_fields[index];
                            if(element.form_detail_field_value.length > rowCounter) {
                                rowCounter = element.form_detail_field_value.length
                            }
                        }

                        let sorted_detail_fields = FormManagementJS.sortDetailFields(data.form_detail_fields);

                        this.$nextTick(() => {
                            this.$refs._formDataRoute.control_number = `Ref. No.: ${item.reference_number}`
                            this.$refs._formDataRoute.detailsRowCount = rowCounter;
                            this.$refs._formDataRoute.organization_logo = this.$store.getters.getOrganizationLogo;
                            this.$refs._formDataRoute.header_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'header');
                            this.$refs._formDataRoute.detail_fields = FormManagementJS.generateDetailField(sorted_detail_fields);
                            this.$refs._formDataRoute.footer_fields = FormManagementJS.generateHeaderFooterField(data.form_header_footer_fields, 'footer');
                            this.$refs._formDataRoute.header_footer_field_values = FormManagementJS.generateHeaderFooterFieldValue(data.form_header_footer_fields);
                            this.$refs._formDataRoute.detail_field_values = FormManagementJS.generateDetailFieldValue(data.form_detail_fields);

                            setTimeout(() => {
                                this.createPDF().then(response => {
                                    this.$store.commit("SET_FORM_DATA_PDF_BASE64", response);
                                    this.$store.commit("SET_FORM_DATA_PDF_REFERENCE_NUMBER", item.reference_number);
                                });
                            }, 200); // .2 seconds delay seconds delay
                            this.$Progress.finish()
                        })
                    })
                break;   

                default:
                    let data = {
                        reference_number:item.reference_number,
                        printout_source:this.printout_source,
                        action:'route'
                    }
                    axios.post('/form-managements/form-data/route-custom-form',data,{validateStatus: () => true}).then(response => {
                        if(response.status == 200) {
                            this.$store.commit("SET_FORM_DATA_PDF_BASE64", response.data);
                            this.$store.commit("SET_FORM_DATA_PDF_REFERENCE_NUMBER", item.reference_number);
                            this.$Progress.finish()
                        }
                    })

                break;
            }

        },
        createPDF: function () {
            return new Promise(async (resolve, reject) => {
                const options = {
                    margin: [.5,.5],
                    image: {type: 'jpeg', quality: 0.98 },
                    html2canvas: {dpi: 300, letterRendering: true,useCORS: true},
                    jsPDF: { unit: 'in', format: 'legal', orientation: 'portrait' },
                    pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
                }
                try {
                    const base64 = html2pdf().set(options).from(document.getElementById('form_data')).outputPdf().then( (pdf) => {
                        return btoa(pdf)
                    })

                    // const base64 = html2pdf().set(options).from(document.getElementById('form_data')).outputPdf().save()
                    resolve(base64);
                } catch (e) {
                    reject(e);
                }
            });
        },
        searchData(){
            this.$Progress.start();
            let list = this.form_data_list;
            if (this.my_tab == 0) {
                list = this.form_data_list.filter(x => x.deleted_at == null);
                this.filtered_data = list
                    .filter(item => item.reference_number.includes(this.to_search.reference_number))
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                this.current_page_active = 1; // Reset to first page
            } else {
                list = this.form_data_list.filter(x => x.deleted_at != null);
                this.filtered_data_inactive = list
                    .filter(item => item.reference_number.includes(this.to_search.reference_number))
                    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                this.current_page_inactive = 1; // Reset to first page
            }
            this.$Progress.finish();
        },
        searchDataAdvanced(){
            this.list_loaded = false
            this.submit_advance_filter = true;
            this.numberOfFilter = 0;
           
            if(this.to_search.form) {
                this.numberOfFilter++;
            }
            if(this.to_search.status) {
                this.numberOfFilter++;
            }
            if((this.$refs._CDateRange.range && this.$refs._CDateRange.range.start) && (this.$refs._CDateRange.range && this.$refs._CDateRange.range.end)){
                    let startDate = this.$refs._CDateRange.range.start;
                    let endDate = this.$refs._CDateRange.range.end;
                    this.numberOfFilter++;
                    this.to_search.date_from = startDate.getFullYear() + "-" + ("0"+(startDate.getMonth()+1)).slice(-2) +"-"+ ("0" + startDate.getDate()).slice(-2) + "T00:00:00.000Z";
                    this.to_search.date_to = endDate.getFullYear() + "-" + ("0"+(endDate.getMonth()+1)).slice(-2) +"-"+ ("0" + endDate.getDate()).slice(-2) + "T23:59:59.999Z";
            } else {
                this.to_search.date_from = null,
                this.to_search.date_to = null
            }
    
            if(!this.to_search.form &&  this.to_search.date_from==null && this.to_search.date_to == null && this.to_search.status == null) {
                this.submit_advance_filter = false;
                this.clearSearch();
               return this.$swal({
                    toast: true,
                    position: "top-right",
                    showConfirmButton: false,
                    timer: 3000,
                    icon: "error",
                    title: "Please Select a filter!",
                    timerProgressBar: true,
                })
            }
            
            this.getFormsData();
            
        },
        advanceFilter: function () {
            this.getForms();
            this.to_search.reference_number = null
            this.custom_filter = true;
        },
        clearSearch: function () {
            this.to_search.reference_number = null,
            this.to_search.form = null,
            this.to_search.date_from = null,
            this.to_search.date_to = null,
            this.to_search.status = null
            this.$refs._CDateRange.range = null,
            this.filtered_data=null,
            this.filtered_data_inactive=null
            this.numberOfFilter=0;
            this.getFormsData();
        },
        getForms: function () {
            this.$Progress.start()
            axios.get('/form-managements/form/active-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.form_list = response.data.data
                    this.$Progress.finish()
                }
            })
        },
        updateForms() {
            this.updateActiveForms();
            this.updateInactiveForms();
        },
        updateActiveForms() {
            let active_list = this.form_data_list.filter(item => item.deleted_at == null);
            this.active_forms_list = active_list;
            this.total_records_active = this.active_forms_list.length;
        },
        updateInactiveForms() {
            let inactive_list = this.form_data_list.filter(item => item.deleted_at != null);
            this.inactive_forms_list = inactive_list;
            this.total_records_inactive = this.inactive_forms_list.length;
        },
        updateActivePage(page) {
            this.current_page_active = page;
        },
        updateInactivePage(page) {
            this.current_page_inactive = page;
        },
    },
    watch: {
        '$store.state.formDataPdfBase64': function (value) {
            if(value) {
                this.$router.push(`/${this.$store.getters.getOrganizationName}/general-access/document-routing/create`);
            }
        },
        preview_form_data: function (value) {
            if(!value) this.previewing_form_data = false;
        },
        // form_data_list() {
        //     this.paginateData();
        // },
        // current_page() {
        //     this.paginateData();
        // },

        form_data_list() {
            this.updateForms();
        },
        current_page_active() {
            this.updateActiveForms();
        },
        current_page_inactive() {
            this.updateInactiveForms();
        },
    },
    mounted() {
        this.getFormsData();
    },
    
}
</script>

<style>
.td-actn-fxd-wdth {
    width: 210px !important;
}

.th-icon {
    width: 70px !important;
}
.card-container {
    padding-right:5px;
}
</style>